import "../styles/globals.css";
import { ThemeProvider } from "next-themes";
import Layout from "../components/layout";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { useRouter } from "next/router";
import { MetaMaskProvider } from "metamask-react";
import Meta from "../components/Meta";
import UserContext from "../components/UserContext";
import { useRef, useState } from "react";
import ConstructionPage from '../components/ConstructionPage';

if (typeof window !== "undefined") {
  require("bootstrap/dist/js/bootstrap");
}

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const pid = router.asPath;
  const scrollRef = useRef({
    scrollPos: 0,
  });

  const [underConstruction, setUnderConstruction] = useState(true); // Variável de estado para controlar a exibição da página de construção

  const toggleUnderConstruction = () => {
    setUnderConstruction(!underConstruction);
  };

  return (
    <>
      <Meta title="Home 1" />

      <Provider store={store}>
        <ThemeProvider enableSystem={true} attribute="class">
          <MetaMaskProvider>
            <UserContext.Provider value={{ scrollRef: scrollRef }}>
            {underConstruction ? ( 
                <ConstructionPage toggleUnderConstruction={toggleUnderConstruction} /> ) :// Componente da página de construção temporária
              
              pid === "/login" ? (
                <Component {...pageProps} />
              ) : (
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              )}
            </UserContext.Provider>
          </MetaMaskProvider>
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default MyApp;


// return (
//   <>
//     <Meta title="Home 1" />

//     <Provider store={store}>
//       <ThemeProvider enableSystem={true} attribute="class">
//         <MetaMaskProvider>
//           <UserContext.Provider value={{ scrollRef: scrollRef }}>
//             {underConstruction ? ( // Verifica se a página de construção deve ser exibida
//               <ConstructionPage toggleUnderConstruction={toggleUnderConstruction} /> // Componente da página de construção temporária
//             ) : (
//               <>
//                 {pid === "/login" ? (
//                   <Component {...pageProps} />
//                 ) : (
//                   <Layout>
//                     <Component {...pageProps} />
//                   </Layout>
//                 )}
//               </>
//             )}
//           </UserContext.Provider>
//         </MetaMaskProvider>
//       </ThemeProvider>
//     </Provider>
//   </>
// );
// }